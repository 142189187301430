import React, { useContext, useEffect } from "react";
import { Link } from "gatsby";
import { useAsyncFn } from "react-use";

// Context
import { StoreContext } from "../context/store-context";

// Icons
import { CloseFiltersIcon } from "../icons/close-filters-icon";

// Utils
import { formatPrice } from "../utils/format-price";
import kebabCase from "lodash.kebabcase";

export const LineItem = ({ item, allArtistNames }) => {
  const { removeLineItem, checkout, updateLineItem, loading } =
    useContext(StoreContext);

  const price = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount)
  );

  const subtotal = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount) * 1
  );

  const handleRemove = () => {
    removeLineItem(checkout.id, item.id);
  };

  const artistName = allArtistNames
    .filter((artist) => artist !== null)
    .filter((artist) => artist.handle === item.variant.product.handle);

  return (
    <div className="row item">
      <div className="item-remove">
        <button onClick={handleRemove}>
          <CloseFiltersIcon />
        </button>
      </div>

      <div className="item-title">
        <h2>
          <Link
            to={`/${kebabCase(artistName[0].artist)}/${
              item.variant.product.handle
            }/`}
          >
            {item.title}
          </Link>
        </h2>
        {artistName.length >= 1 && (
          <h3>
            <Link
              to={`/shop/`}
              state={{ activeArtistFilter: artistName[0].artist }}
            >
              {artistName[0].artist}
            </Link>
          </h3>
        )}
      </div>

      <div className="item-price">{price}</div>

      <div className="item-subtotal">{subtotal}</div>
    </div>
  );
};
