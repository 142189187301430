import React, { useContext, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";

// SEO
import { PageSeo } from "../components/seo/seo";

// Context
import { PageType } from "../components/context/page-type";
import { StoreContext } from "../components/context/store-context";

// Components
import { LineItem } from "../components/product/line-item";

// Icons
import { MailchimpArrow, MobileArrow } from "../components/icons/arrows";

// Utils
import { formatPrice } from "../components/utils/format-price";

const Page = styled.div`
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.04em;

  & .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    height: 75px;

    transition: 249ms background-color ease;
    background-color: #e9cfc0;

    z-index: 1;

    @media (max-width: 800px) {
      height: 56px;
    }
  }

  @media (max-width: 1440px) {
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-width: 500px) {
    font-size: 10px;
    line-height: 14px;
  }

  & .page-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;

    min-height: calc(${(props) => props.height}px - 190px - 20px);

    @media (max-width: 800px) {
      min-height: calc(${(props) => props.height}px - 90px - 15px);
    }
  }

  & .empty-cart {
    & p {
      font-size: 20px;
      line-height: 28px;

      @media (max-width: 1440px) {
        font-size: 16px;
        line-height: 22px;
      }

      @media (max-width: 500px) {
        font-size: 10px;
        line-height: 14px;
      }

      & a {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;

        color: #91a8b7;
        transition: 250ms color ease;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: #000;

            & svg {
              &.desktop-arrow {
                & path {
                  stroke: #000;
                }
              }

              &.mobile-arrow {
                & path {
                  fill: #000;
                }
              }
            }
          }
        }
      }
    }

    & svg {
      width: 98px;
      margin: 0 0 0 20px;

      &.desktop-arrow {
        & path {
          stroke: #91a8b7;
        }
      }

      & path {
        transition: 250ms stroke ease;
      }

      &.mobile-arrow {
        display: none;

        & path {
          fill: #91a8b7;
        }
      }

      @media (max-width: 800px) {
        &.desktop-arrow {
          display: none;
        }

        &.mobile-arrow {
          display: block;
          width: auto;

          width: 35px;

          margin: 0 0 0 15px;
        }
      }
    }
  }

  & .buttons-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;

    & a {
      height: 50px;
      min-width: 115px;

      padding: 0 15px;
      margin: 0 20px 0 0;

      font-size: 20px;
      line-height: 50px;
      text-align: center;

      border: 1px solid #000000;
      transition: 250ms border-color ease, 250ms background-color ease;

      &:first-of-type {
        border: 1px solid transparent;
        background-color: #87a3b4;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            border-color: #000;
            background-color: transparent;
          }
        }
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          border-color: #87a3b4;
          background-color: #87a3b4;
        }
      }

      @media (max-width: 1440px) {
        font-size: 16px;
      }

      @media (max-width: 800px) {
        height: 30px;
        min-width: 70px;
        line-height: 27px;

        padding: 0 9px;
        margin: 0 15px 0 0;
      }

      @media (max-width: 500px) {
        font-size: 10px;
      }
    }
  }
`;

const Table = styled.div`
  width: 100%;

  & .row {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 20px;

    @media (max-width: 800px) {
      grid-template-columns: repeat(7, 1fr);
      grid-column-gap: 15px;
    }

    &.headings {
      padding: 0 0 15px 0;
      border-bottom: 1px solid #658ba5;

      & .item-title {
        grid-column: 2 / 11;

        @media (max-width: 800px) {
          grid-column: 2 / 6;
        }
      }

      & .price-title,
      & .total-price-title {
        grid-column: span 1;
      }

      @media (max-width: 500px) {
        padding: 0 0 5px 0;
      }
    }

    &.item {
      align-items: center;

      padding: 15px 0;
      border-bottom: 1px solid #658ba5;

      @media (max-width: 500px) {
        padding: 5px 0;
      }

      & .item-remove {
        grid-column: 1;
        height: 16px;

        & button {
          height: 16px;
        }

        @media (max-width: 500px) {
          height: 10px;

          & button {
            height: 10px;

            & svg {
              width: 10px;
              height: 10px;
            }
          }
        }
      }

      & .item-title {
        grid-column: 2 / 11;

        @media (max-width: 800px) {
          grid-column: 2 / 6;
        }

        & h2 {
          font-size: 16px;
          line-height: 22px;

          @media (max-width: 1440px) {
            font-size: 14px;
            line-height: 20px;
          }

          @media (max-width: 500px) {
            font-size: 10px;
            line-height: 14px;
          }
        }

        & h3 {
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 0.08em;

          text-transform: uppercase;

          @media (max-width: 1440px) {
            font-size: 14px;
            line-height: 20px;
          }

          @media (max-width: 500px) {
            font-size: 10px;
            line-height: 14px;
          }
        }
      }
    }

    &.totals {
      & .container {
        grid-column: 10 / 13;

        @media (max-width: 1150px) {
          grid-column: 7 / 13;
        }

        @media (max-width: 800px) {
          grid-column: 2 / 8;
        }

        & .line {
          padding: 15px 0;
          border-bottom: 1px solid #658ba5;

          @media (max-width: 500px) {
            padding: 5px 0;
          }
        }

        & .inner-grid-container {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-column-gap: 20px;

          @media (max-width: 1150px) {
            grid-template-columns: repeat(6, 1fr);
          }

          @media (max-width: 800px) {
            grid-column-gap: 15px;
          }

          & .column {
            &.left {
              grid-column: 1;

              @media (max-width: 1150px) {
                grid-column: 1 / 4;
              }
            }

            &.right {
              grid-column: 3;

              @media (max-width: 1150px) {
                grid-column: 6;
              }
            }
          }
        }
      }
    }
  }
`;

const Cart = () => {
  const [pageType, setPageType] = useContext(PageType);
  const { checkout, loading } = useContext(StoreContext);
  const emptyCart = checkout.lineItems.length === 0;

  const height = use100vh();

  useEffect(() => {
    setPageType({
      page: `cart`,
      background: `#E9CFC0`,
      banner: false,
      bannerBackground: `#BDBFB7`,
      fillColor: `#91A8B7`,
    });
  }, []);

  const getArtistName = (artwork) => {
    const metafields =
      artwork.metafields.length >= 1 ? artwork.metafields : null;

    const artistMetafield =
      metafields !== null
        ? metafields
            .filter((field) => field.key === "artist")
            .map((field) => field.value)
        : null;

    if (artistMetafield !== null && artistMetafield.length >= 1) {
      return {
        artist: artistMetafield[0],
        handle: artwork.handle,
      };
    } else {
      return null;
    }
  };

  const data = useStaticQuery(graphql`
    {
      allShopifyProduct {
        nodes {
          handle
          metafields {
            value
            key
          }
        }
      }
    }
  `);

  const allArtistNames = data.allShopifyProduct.nodes.map((artist) =>
    getArtistName(artist)
  );

  return (
    <>
      <PageSeo seoTitle="Cart | RAW Editions" seoImage={null} seoText={null} />

      <Page height={height}>
        <div className="header" />

        <div className="page-container">
          {emptyCart ? (
            <div className="empty-cart">
              <p>
                Your cart is currently empty. Sometimes it is hard to choose, we
                know.
              </p>
              <p>
                <Link to="/shop/" className="">
                  Browse our newest acquisitions
                  <MailchimpArrow />
                  <MobileArrow />
                </Link>
              </p>
            </div>
          ) : (
            <>
              <Table>
                <div className="row headings">
                  <div className="item-title">Item</div>
                  <div className="price-title">Price</div>
                  <div className="total-price-title">Total</div>
                </div>

                {checkout.lineItems.map((item) => (
                  <LineItem
                    item={item}
                    key={item.id}
                    allArtistNames={allArtistNames}
                  />
                ))}

                <div className="row totals">
                  <div className="container">
                    <div className="inner-grid-container line">
                      <div className="column left">Subtotal</div>
                      <div className="column right">
                        {formatPrice(
                          checkout.subtotalPriceV2.currencyCode,
                          checkout.subtotalPriceV2.amount
                        )}
                      </div>
                    </div>
                    <div className="line">Shipping calculated at checkout</div>
                    <div className="inner-grid-container line">
                      <div className="column left">Total Price</div>
                      <div className="column right">
                        {formatPrice(
                          checkout.totalPriceV2.currencyCode,
                          checkout.totalPriceV2.amount
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Table>

              <div className="buttons-container">
                <a href={checkout.webUrl}>Checkout</a>
                <Link to={`/shop/`}>Continue Shopping</Link>
              </div>
            </>
          )}
        </div>
      </Page>
    </>
  );
};

export default Cart;
